<template>
  <div class="app-container">
    <!-- Synchronize To CDN Status -->
    <template v-if="leaderBoard_Apply_Settings_Status === APPLY_STATUS_ENABLE">
      <el-card class="box-card">
        <div>
          <el-button
            type="primary"
            size="small"
            @click="onApplyLeaderBoardSettings"
            >Apply LeaderBoard Settings
          </el-button>
        </div>
      </el-card>
      <br />
    </template>

    <template
      v-if="leaderBoard_Apply_Settings_Status === APPLY_STATUS_PROCESSING"
    >
      <el-card class="box-card notice-style">
        <div>
          <span
            >Apply Settings Processing...{{
              leaderBoard_Processing_Time_Inc
            }}s</span
          >
        </div>
      </el-card>
      <br />
    </template>

    <template v-if="leaderBoard_Apply_Settings_Status === APPLY_STATUS_DONE">
      <el-card class="box-card notice-style">
        <div>
          <span>Application Of Settings Has Done.</span>
        </div>
      </el-card>
      <br />
    </template>
    <!-- End Synchronize To CDN Status -->

    <el-tabs v-model="activeTab" @tab-click="handleClick" v-loading="loading">
      <!-- Begin: Leaderboard Status -->
      <el-tab-pane label="Status" name="Status">
        <el-table
          :data="leaderBoardStatusDecorationList"
          border
          style="width: 100%"
        >
          <el-table-column label="Name" width="250px">
            <template slot-scope="{ row }">
              <span>{{ row.AppID }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Status" width="150px">
            <template slot-scope="{ row }">
              <span>{{ row.EventStatus.Status }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Current Time" width="250px">
            <template slot-scope="{ row }">
              <el-date-picker
                v-model="row.DisplayEventStatus.CurrentTime"
                type="datetime"
                placeholder="Select date and time"
                format="dd/MM/yyyy HH:mm:ss"
                :readonly="true"
              >
              </el-date-picker>
            </template>
          </el-table-column>

          <el-table-column label="Next Reset" width="250px">
            <template slot-scope="{ row }">
              <el-date-picker
                v-model="row.DisplayEventStatus.NextReset"
                type="datetime"
                placeholder="Select date and time"
                format="dd/MM/yyyy HH:mm:ss"
                :readonly="true"
              >
              </el-date-picker>
            </template>
          </el-table-column>

          <el-table-column label="Wait Before Reset (seconds)" width="150px">
            <template slot-scope="{ row }">
              <span>{{ row.EventStatus.IntervalBeforeReset }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <!-- End: Leaderboard Status -->

      <!-- Begin: Leaderboard Settings Information -->
      <el-tab-pane label="Settings Information" name="Information">
        <el-table
          :data="leaderBoardSettingsDecorationList"
          border
          style="width: 100%"
        >
          <el-table-column label="ID" width="50px">
            <template slot-scope="{ row }">
              <span>{{ row.id }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Setting Group Name" width="200px">
            <template slot-scope="{ row }">
              <span>{{ row.data.name }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Event ID" width="120px">
            <template slot-scope="{ row }">
              <template v-if="row.editMode">
                <el-input
                  v-model="row.data.EventInfo.eventID"
                  class="edit-input"
                  size="small"
                />
              </template>
              <span v-else>{{ row.data.EventInfo.eventID }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Event Name" width="250px">
            <template slot-scope="{ row }">
              <template v-if="row.editMode">
                <el-input
                  v-model="row.data.EventInfo.eventName"
                  class="edit-input"
                  size="small"
                />
              </template>
              <span v-else>{{ row.data.EventInfo.eventName }}</span>
            </template>
          </el-table-column>

          <el-table-column label="Date" width="220px">
            <template slot-scope="{ row }">
              <template v-if="row.editMode">
                From:
                <el-date-picker
                  v-model="row.dataDisplay.EventInfo.startDate"
                  type="datetime"
                  placeholder="Select date and time"
                  format="dd/MM/yyyy HH:mm:ss"
                >
                </el-date-picker>
                <br /><br />
                To:
                <el-date-picker
                  v-model="row.dataDisplay.EventInfo.endDate"
                  type="datetime"
                  placeholder="Select date and time"
                  format="dd/MM/yyyy HH:mm:ss"
                >
                </el-date-picker>
              </template>
              <span v-else
                >From:
                {{
                  row.dataDisplay.EventInfo.startDate.toLocaleString("en-GB", { hour12: false })
                }}
                <br /><br />
                To:
                {{ row.dataDisplay.EventInfo.endDate.toLocaleString("en-GB", { hour12: false }) }}
              </span>
              <br />
            </template>
          </el-table-column>

          <el-table-column label="Groups & Rewards" width="150px" type="expand">
            <template slot-scope="{ row }">
              <el-collapse>
                <el-collapse-item title="Groups" name="1">
                  <el-card class="box-card" style="min-height: 150px">
                    <leader-board-group-list
                      v-bind:propID="row.id"
                      v-bind:propData="row.data.Groups"
                      v-bind:propEditMode="row.editMode"
                    ></leader-board-group-list>
                  </el-card>
                </el-collapse-item>
                <el-collapse-item title="Rewards" name="2">
                  <el-card class="box-card" style="min-height: 150px">
                    <leader-board-reward-list
                      v-bind:propID="row.id"
                      v-bind:propData="row.data.Rewards"
                      v-bind:propEditMode="row.editMode"
                    ></leader-board-reward-list>
                  </el-card>
                </el-collapse-item>
              </el-collapse>
            </template>
          </el-table-column>

          <el-table-column label="Action">
            <template slot-scope="{ row }">
              <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                @click="onDeleteOneLeaderBoardSetting(row)"
              >
                Delete</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <!-- End: Leaderboard Information -->

      <!-- Begin: Add new Leaderboard -->
      <el-tab-pane label="Add New" name="AddNew">
        <el-collapse v-model="activeCollapseName">
          <el-collapse-item name="1">
            <template slot="title">
              <h2>Event Information</h2>
            </template>
            <el-card class="box-card" style="min-height: 150px">
              <el-form ref="form" label-width="200px">
                <el-form-item label="DECORATION Event ID List">
                  <el-select
                    v-model="currentEventID"
                    filterable
                    allow-create
                    placeholder="Select"
                    @change="onSelectEventIDFromList($event)"
                    style="width: 16%"
                  >
                    <el-option
                      v-for="item in eventInformationList"
                      :key="item.eventID"
                      :label="item.eventID"
                      :value="item.eventID"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <br />

                <el-form-item label="Event ID" style="width: 40%">
                  <el-input
                    v-model="currentEventInformation.eventID"
                    class="edit-input"
                    placeholder="Auto fill when selecting Event ID !"
                    @change="OnChangeEventID($event)"
                  />
                </el-form-item>
                <br />

                <el-form-item label="Event Name" style="width: 40%">
                  <el-input
                    v-model="currentEventInformation.eventName"
                    class="edit-input"
                    placeholder="Auto fill when selecting Event ID !"
                    @change="OnChangeEventName($event)"
                  />
                </el-form-item>

                <br />

                <el-form-item label="Start Date Time">
                  <el-date-picker
                    v-model="currentEventInformation.startDateTime"
                    type="datetime"
                    placeholder="Auto fill !"
                    @change="OnChangeGroupBeginDate($event)"
                  >
                  </el-date-picker>
                </el-form-item>
                <br />
                <el-form-item label="End Date Time">
                  <el-date-picker
                    v-model="currentEventInformation.endDateTime"
                    type="datetime"
                    placeholder="Auto fill !"
                    @change="OnChangeGroupEndDate($event)"
                  >
                  </el-date-picker>
                </el-form-item>
                <br />
                <!-- <el-form-item
              label="Lock Interval Before End (Seconds)"
              style="width: 16%"
            >
              <el-input
                v-model="LeaderBoardItem_EventDate_Lock_Before_End"
                class="edit-input"
                placeholder=""
              />
            </el-form-item> -->
              </el-form>
            </el-card>
          </el-collapse-item>

          <el-collapse-item name="2">
            <template slot="title">
              <h2>Leaderboard Sets</h2>
            </template>
            <el-card class="box-card" style="min-height: 150px">
              <el-form ref="form" label-width="200px">
                <el-form-item label="Total Time (s):" style="width: 40%">
                  <el-input
                    v-model="currentTimer.totalTime"
                    class="edit-input"
                    placeholder="seconds"
                    @change="onChangeTimer()"
                  />
                </el-form-item>

                <el-form-item label="Active Time (s):" style="width: 40%">
                  <el-input
                    v-model="currentTimer.activeTime"
                    class="edit-input"
                    placeholder="seconds"
                    @change="onChangeTimer()"
                  />
                </el-form-item>

                <el-form-item label="Reward Time (s):" style="width: 40%">
                  {{ currentTimer.rewardTime }}
                </el-form-item>

                <el-form-item
                  label="Total Event Days(Sets):"
                  style="width: 40%"
                >
                  {{ currentTimer.totalGroups }}
                </el-form-item>

                <el-form-item label="Total LDB Days(Sets):" style="width: 40%">
                  {{ currentTimer.totalSettingGroups }}
                </el-form-item>

                <el-form-item label="Shop Days(Sets):" style="width: 40%">
                  1
                </el-form-item>
                <span style="color: red; font-style: italic">
                  (Tính toán sao mà ra số dư là số lẽ là sai nha!)
                </span>
              </el-form>
            </el-card>
          </el-collapse-item>

          <el-collapse-item name="3">
            <template slot="title">
              <h2>Leaderboard User Groups</h2>
            </template>
            <el-card class="box-card" style="min-height: 150px">
              <el-form ref="form" label-width="200px">
                <el-form-item label="OS (Auto Detected)">
                  <el-select
                    v-model="addLDBGroup.osName"
                    placeholder="Select"
                    style="width: 20%"
                  >
                    <el-option
                      v-for="item in osList"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <br />

                <el-form-item label="User Segment">
                  <el-select
                    v-model="addLDBGroup.userSegment"
                    placeholder="Select"
                    style="width: 20%"
                  >
                    <el-option
                      v-for="item in userSegmentList"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>

                  <template v-if="addLDBGroup.userSegment.includes('us')">
                    <span>
                      <br />(all users in the tier1 countries: US, GB, FR, DE,
                      IT, AU, CA, KR, JP, TW, HK)
                    </span>
                  </template>

                  <template v-if="addLDBGroup.userSegment.includes('tier3')">
                    <span>
                      <br />(all users in the tier3 countries: IN, PH, ID)
                    </span>
                  </template>
                </el-form-item>
                <br />

                <el-form-item label="Total LDB" style="width: 20%">
                  <el-input
                    v-model="addLDBGroup.count"
                    class="edit-input"
                    placeholder=""
                  />
                </el-form-item>
                <br />

                <el-form-item label="Total Payment Users" style="width: 20%">
                  <el-input
                    v-model="addLDBGroup.payment_size"
                    class="edit-input"
                    placeholder="Payment Size <= Size"
                  />
                </el-form-item>
                <br />

                <el-form-item
                  label="Total None Payment Users (Default Fill)"
                  style="width: 20%"
                >
                  <el-input
                    v-model="addLDBGroup.none_payment_limit_size_1"
                    class="edit-input"
                    placeholder="None Payment Limit Size <= Size"
                  />
                </el-form-item>
                <br />

                <el-form-item label="Total Users In LDB" style="width: 20%">
                  <el-input
                    v-model="addLDBGroup.size"
                    class="edit-input"
                    placeholder=""
                  />
                </el-form-item>
                <br />
              </el-form>

              <el-button
                type="success"
                size="small"
                icon="el-icon-edit-outline"
                @click="onAddLDBGroup()"
                >Add Group
              </el-button>

              <el-button
                type="success"
                size="small"
                icon="el-icon-edit-outline"
                @click="onAddLDBGroupQuick()"
                >Quick Add Group Default</el-button
              >

              <br />
              <br />

              <el-table :data="addLDBGroupList" border style="width: 100%">
                <el-table-column prop="name" label="Group Name" width="180">
                </el-table-column>
                <el-table-column
                  prop="count"
                  label="Total LDB In Group"
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  prop="payment_size"
                  label="Total Payment Users"
                >
                </el-table-column>
                <el-table-column
                  prop="none_payment_limit_size_1"
                  label="Total None Payment Users (Default Fill)"
                >
                </el-table-column>
                <el-table-column prop="size" label="Total Users">
                </el-table-column>

                <el-table-column label="Action">
                  <template slot-scope="{ row }">
                    <el-button
                      type="danger"
                      size="small"
                      icon="el-icon-delete"
                      @click="onDeleteOneLDBGroup(row)"
                      >Delete
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-card>
          </el-collapse-item>

          <el-collapse-item name="4">
            <template slot="title">
              <h2>Leaderboard Rewards</h2>
            </template>
            <el-card class="box-card" style="min-height: 150px">
              <el-form ref="form" label-width="200px">
                <el-form-item label="Import Rewards Files:" style="width: 50%">
                  <el-upload
                    class="upload-demo"
                    drag
                    action=""
                    ref="upload2"
                    :auto-upload="false"
                    :multiple="true"
                    :on-change="onHandleRewardFileUpload"
                    :on-remove="onHandleRewardFileRemove"
                  >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">Drop *.mlb files here!</div>
                  </el-upload>
                </el-form-item>

                <el-form-item>
                  <el-button type="primary" @click="onProcessRewards"
                    >Loading Rewards</el-button
                  >
                </el-form-item>
              </el-form>

              <br />
              <br />

              <div class="info">
                <div v-for="(item, index) in addLDBRewardList" :key="index">
                  <el-collapse v-model="activeCollapseName">
                    <el-collapse-item>
                      <template slot="title">
                        Rewards Day {{ index + 1 }}
                      </template>

                      <el-table :data="item.item" border style="width: 100%">
                        <el-table-column prop="id" label="ID" width="50">
                        </el-table-column>

                        <el-table-column prop="title" label="Title" width="180">
                        </el-table-column>

                        <el-table-column
                          prop="rankMin"
                          label="Rank Min"
                          width="100"
                        >
                        </el-table-column>

                        <el-table-column
                          prop="rankMax"
                          label="Rank Max"
                          width="100"
                        >
                        </el-table-column>

                        <el-table-column
                          prop="requiredPoint"
                          label="Require Point"
                          width="200"
                        >
                        </el-table-column>

                        <el-table-column prop="gifts" label="Rewards">
                        </el-table-column>
                      </el-table>
                    </el-collapse-item>
                  </el-collapse>
                </div>
              </div>
            </el-card>
          </el-collapse-item>
        </el-collapse>

        <br />
        <br />

        <el-button
          type="success"
          size="small"
          icon="el-icon-edit-outline"
          @click="onLeaderBoardItem_AddFull()"
          >Add Leader Board</el-button
        >
      </el-tab-pane>
      <!-- End: Add new Leaderboard -->
    </el-tabs>
  </div>
</template>


<script>
import { Message, MessageBox } from "element-ui";
import { getEventDataFromFirebase } from "@/api/dynamic_events";
import { getOSToken } from "@/utils/auth";
import LeaderBoardGroupList from "@/components/CompLeaderBoardGroupList";
import LeaderBoardRewardList from "@/components/CompLeaderBoardRewardList";
import {
  TryParseUploadFile_ReadTextFile,
  HelperParseRewardFromFile,
  HelperGetLeaderBoardAllSettings,
  HelperApplyLeaderBoardAllSettingsPolling,
  HelperGetStatusPolling,
  HelperGetEventDataList,
  HelperSetLeaderBoardAddMultiSettings,
  HelperSetLeaderBoardRemoveMultiSettings,
} from "@/api/gameservice";

var fs = require("fs");

// Status for Apply Settings
const APPLY_STATUS_READY = "READY";
const APPLY_STATUS_ENABLE = "ENABLE";
const APPLY_STATUS_PROCESSING = "PROCESSING";
const APPLY_STATUS_DONE = "DONE";
const EVENT_TYPE_DECORATION = "Decoration";
const LeaderBoardItem_EventDate_Lock_Before_End = 300; //300s

export default {
  name: "GameServiceDecoration",
  components: { LeaderBoardGroupList, LeaderBoardRewardList },
  data() {
    return {
      loading: false,
      addLDBGroup: {
        name: "",
        osName: "Android",
        userSegment: "us",
        count: 1,
        payment_size: 20,
        none_payment_limit_size_1: 80,
        size: 100,
      },
      activeTab: "Status",
      activeCollapseName: ["1"],
      osList: ["ANDROID", "IOS"],
      userSegmentList: ["us", "tier3", "others", "cheaters", "testers"],
      eventType: "EVENT_DECORATION",
      currentEventInformation: {},
      currentTimer: {},
      currentEventID: "",
      eventInformationList: [],
      leaderBoardSettingsTotalList: [],
      leaderBoardSettingsDecorationList: [],
      leaderBoardItem: {},
      addLDBGroupList: [],
      addLDBRewardList: [],
      addFileList: [],

      leaderBoard_Apply_Settings_Status: "",
      leaderBoard_Processing_Time_Inc: 0,

      APPLY_STATUS_READY: APPLY_STATUS_READY,
      APPLY_STATUS_ENABLE: APPLY_STATUS_ENABLE,
      APPLY_STATUS_PROCESSING: APPLY_STATUS_PROCESSING,
      APPLY_STATUS_DONE: APPLY_STATUS_DONE,
    };
  },
  created() {
    this.loading = false;
    this.currentEventID = "";
    this.currentEventInformation = {};
    this.currentTimer = {
      activeTime: 79200,
      totalTime: 86400,
      rewardTime: 7200,
      totalSettingGroups: 0,
      totalGroups: 0,
    };
    this.eventInformationList = [];
    this.leaderBoardSettingsTotalList = [];
    this.leaderBoardSettingsDecorationList = [];
    this.leaderBoardStatusTotalList = [];
    this.leaderBoardStatusDecorationList = [];
    this.leaderBoardItem = {};
    this.addLDBGroupList = [];
    this.addLDBRewardList = [];
    this.addFileList = [];

    (this.leaderBoard_Apply_Settings_Status = ""),
      (this.leaderBoard_Processing_Time_Inc = 0),
      (this.APPLY_STATUS_READY = APPLY_STATUS_READY),
      (this.APPLY_STATUS_ENABLE = APPLY_STATUS_ENABLE),
      (this.APPLY_STATUS_PROCESSING = APPLY_STATUS_PROCESSING),
      (this.APPLY_STATUS_DONE = APPLY_STATUS_DONE),
      (this.addLDBGroup.osName = getOSToken().toUpperCase());
    this.loadingEventInformation();
    this.getAllLeaderBoardSettings();
    this.OnGetLeaderBoardRuntimeStatusList();
  },
  methods: {
    // ========================================
    IsStringAlphaNumber(input) {
      var letters = /^[0-9a-zA-Z_]+$/;
      if (input.match(letters)) {
        return true;
      } else {
        return false;
      }
    },

    // ========================================
    // load thong tin tat ca Events tu Firebase
    loadingEventInformation() {
      this.eventIDList = [];

      getEventDataFromFirebase()
        .then((response) => {
          response.events.forEach((eventInfo) => {
            if (eventInfo.event_type === this.eventType) {
              let displayDateBegin = new Date(
                eventInfo.date_begin._seconds * 1000
              );
              let displayDateEnd = new Date(eventInfo.date_end._seconds * 1000);

              this.eventInformationList.push({
                eventID: eventInfo.event_id,
                eventName: eventInfo.event_name,
                startDateTime: displayDateBegin,
                endDateTime: displayDateEnd,
                originalStartDateTime: eventInfo.date_begin,
                originalEndDateTime: eventInfo.date_end,
              });
            }
          });
        })
        .catch((error) => {
          Message({
            message: "Can't fetch config data list " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },

    // ========================================
    // load thong tin tat ca Leaderboard tu MTOServer
    getAllLeaderBoardSettings() {
      let beginDate = new Date();
      let endDate = new Date();

      HelperGetLeaderBoardAllSettings()
        .then((response) => {
          // Groups:
          //[
          //  {
          //    name: "prefix1_1",
          //    count: 1,
          //    size: 2
          //  },...
          //]

          //"EventInfo": {
          //      "eventID": 201201,
          //      "startDate": 0,
          //      "endDate": 0
          // }

          this.leaderBoardSettingsTotalList = [];
          let allSettings = response.data.settings;
          this.leaderBoard_Apply_Settings_Status = response.data.status;

          // start a timer to checking status processing next status ...
          if (
            this.leaderBoard_Apply_Settings_Status === APPLY_STATUS_PROCESSING
          ) {
            this.leaderBoard_Processing_Time_Inc = 0;
            this.loading = true;
            setTimeout(() => this.onRecheckApplySettingsStatus(), 5000);
          }

          // redirect info
          let redirectInfo = response.data.redirectInfo;
          if (redirectInfo) {
            //{
            //RedirectEnable: 1,
            //RedirectVersion: "1.2.3",
            //RedirectToServer: "Dev",
            //RedirectToUrl: ""
            //}

            if (redirectInfo.RedirectEnable > 0) {
              this.LeaderBoard_Is_Enable_Redirect = true;

              this.LeaderBoard_Redirect_Message = `All Leaderboard Client Version "${redirectInfo.RedirectVersion}" Will Be Redirected To Server "${redirectInfo.RedirectToServer}" ${redirectInfo.RedirectToUrl}`;
            } else this.LeaderBoard_Is_Enable_Redirect = false;
          }

          //----------------------------------------------

          for (let i = 0; i < allSettings.length; i++) {
            let id = i + 1;

            let startDate = new Date(allSettings[i].EventInfo.startDate * 1000);
            let endDate = new Date(allSettings[i].EventInfo.endDate * 1000);
            let endDateDisplay = new Date(
              allSettings[i].EventInfo.endDate * 1000 -
                LeaderBoardItem_EventDate_Lock_Before_End * 1000
            );

            let eventInfoTmp = {
              eventID: allSettings[i].EventInfo.eventID,
              eventType: allSettings[i].EventInfo.eventType,
              eventName: allSettings[i].EventInfo.eventName,
              startDate: allSettings[i].EventInfo.startDate,
              endDate: allSettings[i].EventInfo.endDate,
            };

            let eventInfoDisplay = {
              eventID: allSettings[i].EventInfo.eventID,
              eventType: allSettings[i].EventInfo.eventType,
              eventName: allSettings[i].EventInfo.eventName,
              startDate: startDate,
              endDate: endDateDisplay,
            };

            //let rewardsTmp = JSON.stringify(allSettings[i].Rewards);
            //Message({message: "Get All Settings Rewards: " + JSON.stringify(allSettings[i].Rewards), type: "error", duration: 5 * 1000});

            let tmpRewards = [];
            for (let j = 0; j < allSettings[i].Rewards.length; j++) {
              tmpRewards.push({
                id: allSettings[i].Rewards[j].id,
                title: allSettings[i].Rewards[j].title,
                rankMin: allSettings[i].Rewards[j].rankMin,
                rankMax: allSettings[i].Rewards[j].rankMax,
                requiredPoint: allSettings[i].Rewards[j].requiredPoint,
                gifts: JSON.stringify(allSettings[i].Rewards[j].gifts),
              });
            }

            tmpRewards.sort(function (a, b) {
              return parseInt(a.id) - parseInt(b.id);
            });

            this.leaderBoardSettingsTotalList.push({
              id: id,
              data: {
                name: allSettings[i].name,
                Groups: allSettings[i].Groups,
                Rewards: tmpRewards,
                EventInfo: eventInfoTmp,
              },
              dataDisplay: {
                EventInfo: eventInfoDisplay,
              },
              editMode: false,
              updateMode: false,
            });
          } //for

          // sort list of settings
          this.leaderBoardSettingsTotalList.sort(function (a, b) {
            if (a.id > b.id) return -1;
            if (a.id < b.id) return 1;
            return 0;
          });

          this.leaderBoardSettingsDecorationList = [];
          let tempID = 0;
          for (let k = 0; k < this.leaderBoardSettingsTotalList.length; k++) {
            if (
              this.leaderBoardSettingsTotalList[k].data.EventInfo.eventType ===
              EVENT_TYPE_DECORATION
            ) {
              tempID++;
              this.leaderBoardSettingsDecorationList.push({
                id: tempID,
                data: this.leaderBoardSettingsTotalList[k].data,
                dataDisplay: this.leaderBoardSettingsTotalList[k].dataDisplay,
                editMode: this.leaderBoardSettingsTotalList[k].editMode,
                updateMode: this.leaderBoardSettingsTotalList[k].updateMode,
              });
            }
          }

          //For Add new leaderboard
          let currDate = new Date();

          // khoi tao LeaderBoard Item
          //this.leaderBoardItem = this.leaderBoardSettingsTotalList[0].data;
          this.leaderBoardItem = {
            name: "",
            Groups: [],
            Rewards: [],
            EventInfo: {
              eventID: 0,
              eventType: "unknown",
              startDate: currDate,
              endDate: currDate,
            },
          };
        })
        .catch((error) => {
          Message({
            message: "Get All Settings Data: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },

    // ========================================
    OnGetLeaderBoardRuntimeStatusList() {
      HelperGetEventDataList()
        .then((response) => {
          //Message({message: "Get Event Data List: " + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

          // eventDataItem:
          //
          //  Status: "IDLE",
          //   isLeaderBoardLocked: false,
          //   LastReset: 0,
          //   NextReset: 0,
          //   IntervalBeforeReset: 300

          this.leaderBoardStatusTotalList = [];
          this.leaderBoardStatusDecorationList = [];

          if (
            response.data.code == 0 &&
            response.data &&
            response.data.eventDataList
          ) {
            for (let i = 0; i < response.data.eventDataList.length; i++) {
              let nextResetDate = new Date(
                response.data.eventDataList[i].EventStatus.NextReset * 1000
              );
              let currentDate = new Date(
                response.data.eventDataList[i].CurrentTime * 1000
              );

              this.leaderBoardStatusTotalList.push({
                AppID: response.data.eventDataList[i].AppID,
                CurrentTime: response.data.eventDataList[i].CurrentTime,
                EventStatus: {
                  Status: response.data.eventDataList[i].EventStatus.Status,
                  LastReset:
                    response.data.eventDataList[i].EventStatus.LastReset,
                  NextReset:
                    response.data.eventDataList[i].EventStatus.NextReset,
                  IntervalBeforeReset:
                    response.data.eventDataList[i].EventStatus
                      .IntervalBeforeReset,
                  eventData: undefined,
                },
                DisplayEventStatus: {
                  NextReset: nextResetDate,
                  CurrentTime: currentDate,
                },
              });
            }

            // refresh current time for 10 seconds
            // if (
            //   this.LeaderBoardServiceList &&
            //   this.LeaderBoardServiceList.length > 0
            // ) {
            //   LeaderBoardServiceList_Timer = setInterval(() => {
            //     this.onRefreshCurrentTimeLeaderBoardStatusList(10);

            //     // Refesh leaderboard all information
            //     //this.onAllLeaderBoardInfo();
            //   }, 10000);
            // }

            //------------------------------------------------------------------------
            // add leadeboard status list
            for (let i = 0; i < this.leaderBoardStatusTotalList.length; i++) {
              let foundDecoration = false;
              for (
                let j = 0;
                j < this.leaderBoardSettingsDecorationList.length;
                j++
              ) {
                if (
                  this.leaderBoardSettingsDecorationList[j].data.name ===
                  this.leaderBoardStatusTotalList[i].AppID
                ) {
                  foundDecoration = true;
                  break;
                }
              }

              if (foundDecoration) {
                this.leaderBoardStatusDecorationList.push(
                  this.leaderBoardStatusTotalList[i]
                );
              }
            }
            //------------------------------------------------------------------------

            //Message({message: "Get Event Data List Show: " + JSON.stringify(response.data.eventDataList), type: "success", duration: 5 * 1000});
          }
        })
        .catch((error) => {
          Message({
            message: "Get Event Data 2: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },

    // ========================================
    onAddLDBGroup() {
      let isExist = false;
      this.addLDBGroup.name =
        "_" +
        this.addLDBGroup.osName.toLowerCase() +
        "_" +
        this.addLDBGroup.userSegment +
        "_";

      for (let idx = 0; idx < this.addLDBGroupList.length; idx++) {
        if (this.addLDBGroupList[idx].name === this.addLDBGroup.name) {
          isExist = true;
          break;
        }
      }

      if (isExist) {
        Message({
          message: "LeaderBoard Group Name is already existing!",
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      let tempGroup = {};
      tempGroup = JSON.parse(JSON.stringify(this.addLDBGroup));

      this.addLDBGroupList.push(tempGroup);
    },

    // ========================================
    onAddLDBGroupQuick() {
      this.userSegmentList.forEach((userSegment) => {
        let isExist = false;
        this.addLDBGroup.name = "_" + this.addLDBGroup.osName.toLowerCase() + "_" + userSegment + "_";
        this.addLDBGroup.userSegment = userSegment.toLowerCase();

        for (let idx = 0; idx < this.addLDBGroupList.length; idx++) {
          if (this.addLDBGroupList[idx].name === this.addLDBGroup.name) {
            isExist = true;
            break;
          }
        }

        if (!isExist) {
          let tempGroup = {};
          tempGroup = JSON.parse(JSON.stringify(this.addLDBGroup));
          this.addLDBGroupList.push(tempGroup);
        }
      });
    },

    // ========================================
    // Add tat ca Leaderboard Items len server
    onLeaderBoardItem_AddFull() {
      if (this.currentTimer.totalSettingGroups > 10) {
        Message({
          message:
            "Too much LeaderBoard Settings Group (<=10): " +
            this.currentTimer.totalSettingGroups,
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      if (
        this.currentTimer.totalSettingGroups !== this.addLDBRewardList.length
      ) {
        Message({
          message: `Total Settings Groups (${this.currentTimer.totalSettingGroups}) is not match Total Rewards (${this.addLDBRewardList.length}) `,
          type: "error",
          duration: 5 * 1000,
        });

        return;
      }

      if (this.currentEventInformation.eventID === undefined) {
        Message({
          message: `Select an Event ID!`,
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (
        this.currentEventInformation.eventName === undefined ||
        this.currentEventInformation.eventName.length <= 0
      ) {
        Message({
          message: `Event Name is empty!`,
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (
        this.currentEventInformation.startDateTime === undefined) {
        Message({
          message: `Start Datetime is not exist!`,
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      if (
        this.currentEventInformation.endDateTime === undefined) {
        Message({
          message: `End Datetime is not exist!`,
          type: "error",
          duration: 5 * 1000,
        });
        return;
      }

      let isExist = false;
      this.leaderBoardSettingsTotalList.forEach((configLDBSettings) => {
        let eventInfo = configLDBSettings.data.EventInfo;
        if (eventInfo.eventID === this.currentEventInformation.eventID) {
          Message({
            message: `LDB of Event ID: ${this.currentEventInformation.eventID} is existed, eventName = ${eventInfo.eventName}`,
            type: "error",
            duration: 5 * 1000,
          });
          isExist = true;
          return;
        }
      });

      if (isExist) {
        return;
      }

      let uploadSettings = [];
      let day = 0;
      this.loading = true;
      this.addLDBRewardList.forEach((reward) => {
        day++;

        let timestamp =
          this.currentEventInformation.originalStartDateTime._seconds;
        let timeStampStart =
          timestamp + (day - 1) * this.currentTimer.totalTime;
        let timeStampEnd =
          timeStampStart + Number(this.currentTimer.activeTime);

        let tempGroups = [];
        tempGroups = JSON.parse(JSON.stringify(this.addLDBGroupList));

        let tempItem = {
          name: "decor_" + this.currentEventInformation.eventID + "_" + day,
          RewardNumber: day,
          Groups: tempGroups,
          Rewards: reward.item,
          EventInfo: {
            eventID: this.currentEventInformation.eventID,
            eventType: EVENT_TYPE_DECORATION,
            eventName: this.currentEventInformation.eventName,
            endDate: timeStampEnd,
            startDate: timeStampStart,
          },
        };

        let isCorrect = this.checkingLeaderboardItemCorrect(tempItem);

        if (isCorrect === true) {
          this.leaderBoardItem = tempItem;

          let tempEventInfo = this.leaderBoardItem.EventInfo;
          let tempStartDate = new Date(tempEventInfo.startDate * 1000);
          let tempEndDate = new Date(
            tempEventInfo.endDate * 1000 -
              LeaderBoardItem_EventDate_Lock_Before_End * 1000
          );
          let eventInfoDisplay = {
            eventID: tempEventInfo.eventID,
            eventType: tempEventInfo.eventType,
            eventName: tempEventInfo.eventName,
            startDate: tempStartDate,
            endDate: tempEndDate,
          };

          let ldbLength = this.leaderBoardSettingsTotalList.length;
          let addItem = {
            id: ldbLength,
            data: {
              name: this.leaderBoardItem.name,
              Groups: this.leaderBoardItem.Groups,
              Rewards: this.leaderBoardItem.Rewards,
              EventInfo: this.leaderBoardItem.EventInfo,
              RewardNumber: this.leaderBoardItem.RewardNumber,
            },
            dataDisplay: {
              EventInfo: eventInfoDisplay,
            },
            editMode: false,
            updateMode: false,
          };

          uploadSettings.push(addItem.data);
          this.leaderBoardSettingsTotalList.push(addItem);
        }
      });

      //-------------------------------------------------------------------------
      // day du lieu len server

      // for (let idx = 0; idx < this.leaderBoardSettingsTotalList.length; idx++) {
      //   uploadSettings.push(this.leaderBoardSettingsTotalList[idx].data);
      // }

      HelperSetLeaderBoardAddMultiSettings(uploadSettings)
        .then((response) => {
          this.loading = false;
          Message({
            message: "Set LeaderBoard All Settings Success!",
            type: "success",
            duration: 5 * 1000,
          });
          this.leaderBoard_Apply_Settings_Status = APPLY_STATUS_ENABLE;
          this.getAllLeaderBoardSettings();
        })
        .catch((error) => {
          this.loading = false;
          Message({
            message: "Set LeaderBoard All Settings: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });

      //-------------------------------------------------------------------------
      // loc du lieu cho Decoration
      this.leaderBoardSettingsDecorationList = [];
      let tempID = 0;
      for (let idx = 0; idx < this.leaderBoardSettingsTotalList.length; idx++) {
        if (
          this.leaderBoardSettingsTotalList[idx].data.EventInfo.eventType ===
          EVENT_TYPE_DECORATION
        ) {
          tempID++;
          this.leaderBoardSettingsDecorationList.push({
            id: tempID,
            data: this.leaderBoardSettingsTotalList[idx].data,
            dataDisplay: this.leaderBoardSettingsTotalList[idx].dataDisplay,
            editMode: this.leaderBoardSettingsTotalList[idx].editMode,
            updateMode: this.leaderBoardSettingsTotalList[idx].updateMode,
          });
        }
      }
    },

    // ========================================
    // Add 1 Leaderboard Item len server
    checkingLeaderboardItemCorrect(newLeaderBoardSettingsItem) {
      //----------
      // check dieu kien cua Leader Board Name
      if (!newLeaderBoardSettingsItem.name) {
        Message({
          message: "LeaderBoard Name is empty!",
          type: "error",
          duration: 5 * 1000,
        });
        return false;
      }

      if (newLeaderBoardSettingsItem.name.indexOf("raidboss") === 0) {
        Message({
          message: 'LeaderBoard Name must not contain prefix "raidboss" !',
          type: "error",
          duration: 5 * 1000,
        });
        return false;
      }

      if (newLeaderBoardSettingsItem.name.indexOf("littleboss") === 0) {
        Message({
          message: 'LeaderBoard Name must not contain "littleboss" !',
          type: "error",
          duration: 5 * 1000,
        });
        return false;
      }

      if (newLeaderBoardSettingsItem.name.indexOf("grandboss") === 0) {
        Message({
          message: 'LeaderBoard Name must not contain "grandboss" !',
          type: "error",
          duration: 5 * 1000,
        });
        return false;
      }

      if (!this.IsStringAlphaNumber(newLeaderBoardSettingsItem.name)) {
        Message({
          message:
            "LeaderBoard Name is only valid for characters alpha, number and '_'",
          type: "error",
          duration: 5 * 1000,
        });
        return false;
      }

      if (
        !newLeaderBoardSettingsItem.Groups ||
        newLeaderBoardSettingsItem.length <= 0
      ) {
        Message({
          message: "LeaderBoard Group List is empty!",
          type: "error",
          duration: 5 * 1000,
        });
        return false;
      }

      if (
        !newLeaderBoardSettingsItem.Rewards ||
        newLeaderBoardSettingsItem.Rewards.length <= 0
      ) {
        Message({
          message: "LeaderBoard Reward List is empty!",
          type: "error",
          duration: 5 * 1000,
        });
        return false;
      }

      //----------
      // check trung voi thong tin LB cu
      newLeaderBoardSettingsItem.name =
        newLeaderBoardSettingsItem.name.toLowerCase();
      let isExist = false;
      for (let idx = 0; idx < this.leaderBoardSettingsTotalList.length; idx++) {
        if (
          newLeaderBoardSettingsItem.name ===
          this.leaderBoardSettingsTotalList[idx].data.name
        ) {
          isExist = true;
        }
      }

      if (isExist) {
        Message({
          message: "LeaderBoard Name is already existing!",
          type: "error",
          duration: 5 * 1000,
        });
        return false;
      }
      // + 300s nay khi endDate de cho server xu ly gi do khong biet, cai nay anh Huy mac dinh
      newLeaderBoardSettingsItem.EventInfo.endDate +=
        LeaderBoardItem_EventDate_Lock_Before_End;

      // change group name, them prefix la event
      let tempName = newLeaderBoardSettingsItem.name;
      for (let idx = 0; idx < newLeaderBoardSettingsItem.Groups.length; idx++) {
        if (newLeaderBoardSettingsItem.Groups[idx].name.startsWith(tempName)) {
          break;
        }

        newLeaderBoardSettingsItem.Groups[idx].name =
          tempName.toLowerCase() + newLeaderBoardSettingsItem.Groups[idx].name;
      }

      return true;
    },

    // ========================================
    onApplyLeaderBoardSettings() {
      this.loading = true;

      HelperApplyLeaderBoardAllSettingsPolling()
        .then((response) => {
          this.loading = false;

          if (response.data.code === 0) {
            if (response.data.message === "SUCCESS") {
              Message({
                message: "Apply LeaderBoard Settings: SUCCESS",
                type: "success",
                duration: 5 * 1000,
              });
            } else {
              Message({
                message: "Apply LeaderBoard Settings: " + response.data.message,
                type: "success",
                duration: 5 * 1000,
              });

              if (response.data.message === APPLY_STATUS_PROCESSING) {
                this.leaderBoard_Apply_Settings_Status =
                  APPLY_STATUS_PROCESSING;
                this.leaderBoard_Processing_Time_Inc = 0;
                this.loading = true;

                // start a timer to checking status processing next status ...
                //
                setTimeout(() => this.onRecheckApplySettingsStatus(), 5000);
              } else {
                this.loading = false;
              }
            }
          } else {
            if (response.data.code === 10) {
              Message({
                message:
                  response.data.message,
                type: "error",
                duration: 5 * 1000,
              });
            } else {
              Message({
                message: "Apply LeaderBoard Settings: FAILED",
                type: "error",
                duration: 5 * 1000,
              });
            }
          }
        })
        .catch((error) => {
          this.loading = false;
          Message({
            message: "Apply LeaderBoard All Settings: " + error,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },

    // ========================================
    onRecheckApplySettingsStatus() {
      HelperGetStatusPolling()
        .then((response) => {
          //Message({message: "Recheck Status Polling ..." + JSON.stringify(response.data), type: "success", duration: 5 * 1000});

          let code = response.data.code;
          let message = response.data.message;

          if (
            message === APPLY_STATUS_PROCESSING ||
            message === APPLY_STATUS_DONE ||
            message === APPLY_STATUS_ENABLE ||
            message === APPLY_STATUS_READY
          ) {
            if (message === APPLY_STATUS_READY) {
              message = APPLY_STATUS_DONE;
            }

            if (message === APPLY_STATUS_DONE) {
              Message({
                message: "Apply Settings ..." + message,
                type: "success",
                duration: 5 * 1000,
              });

              // Get Event Data List
              setTimeout(() => {
                // get all leader board settings
                this.getAllLeaderBoardSettings();
                //this.OnGetLeaderBoardRuntimeStatusList();
              }, 5000);
            }

            this.leaderBoard_Apply_Settings_Status = message;

            // start a timer to checking status processing next status ...
            //
            if (message === APPLY_STATUS_PROCESSING) {
              this.loading = true;

              // If message is processing auto recheck status continuely
              setTimeout(() => this.onRecheckApplySettingsStatus(), 5000);
              this.leaderBoard_Processing_Time_Inc += 5;
            } else {
              this.loading = false;
            }

            //Message({message: "Recheck Status Polling ... " + this.leaderBoard_Apply_Settings_Status, type: "success", duration: 5 * 1000});
          } //other status
          else {
            this.leaderBoard_Apply_Settings_Status = APPLY_STATUS_READY;
            this.loading = false;
            Message({
              message: "Recheck Status ... Unknown status " + message,
              type: "error",
              duration: 30 * 1000,
            });
          }
        })
        .catch((error) => {
          this.leaderBoard_Apply_Settings_Status = APPLY_STATUS_READY;
          this.loading = false;

          Message({
            message: "Apply Settings Failed: " + error,
            type: "error",
            duration: 60 * 1000,
          });
        });
    },

    // ========================================
    onHandleRewardFileRemove(oneFile, fileList) {},

    // ========================================
    onHandleRewardFileUpload(oneFile, fileList) {
      this.addFileList = fileList;
    },
    // ========================================
    onProcessRewards() {
      //let day = 0;
      let newAddLDBRewardList = [];
      this.addLDBRewardList = [];
      this.addFileList.forEach((file) => {
        if (file) {
          TryParseUploadFile_ReadTextFile(file.raw, function (code, data) {
            if (code === 0) {
              HelperParseRewardFromFile(data)
                .then((response) => {
                  if (response.data.code === 0) {
                    Message({
                      message: "Import rewards from file succsessfully",
                      type: "success",
                      duration: 5 * 1000,
                    });

                    let oneDayLDBRewardList = [];

                    for (
                      let idx = 0;
                      idx < response.data.rewards.length;
                      idx++
                    ) {
                      let itemGift = JSON.parse(
                        response.data.rewards[idx].gifts
                      );
                      if (!itemGift) {
                        Message({
                          message:
                            "Invalid Reward content: " +
                            response.data.rewards[idx].gifts,
                          type: "error",
                          duration: 5 * 1000,
                        });

                        return;
                      }

                      oneDayLDBRewardList.push({
                        id: Number(response.data.rewards[idx].id),
                        title: response.data.rewards[idx].title,
                        rankMin: response.data.rewards[idx].rankMin,
                        rankMax: response.data.rewards[idx].rankMax,
                        requiredPoint: response.data.rewards[idx].requiredPoint,
                        gifts: response.data.rewards[idx].gifts,
                      });
                    }

                    //day++;
                    //newAddLDBRewardList[day] = oneDayLDBRewardList;
                    newAddLDBRewardList.push({ item: oneDayLDBRewardList });
                  } else {
                    Message({
                      message: "Set Helper Parse Reward From File ",
                      type: "error",
                      duration: 5 * 1000,
                    });
                  }
                })
                .catch((error) => {
                  Message({
                    message:
                      "[HelperParseRewardFromFile]Set LeaderBoard All Settings 5: " +
                      error,
                    type: "error",
                    duration: 5 * 1000,
                  });
                });
            } else {
              Message({
                message: "Set OnHandleRewardFileUpload code: " + code,
                type: "error",
                duration: 5 * 1000,
              });
            }
          });
        }
      });

      this.addLDBRewardList = newAddLDBRewardList;
    },

    // ========================================
    onChangeTimer() {
      if (
        this.currentEventInformation.startDateTime &&
        this.currentEventInformation.endDateTime
      ) {
        let deltaSeconds =
          this.currentEventInformation.originalEndDateTime._seconds -
          this.currentEventInformation.originalStartDateTime._seconds;

        this.currentTimer.rewardTime =
          this.currentTimer.totalTime - this.currentTimer.activeTime;
        this.currentTimer.totalSettingGroups =
          deltaSeconds / this.currentTimer.totalTime;
        this.currentTimer.totalSettingGroups--;
        this.currentTimer.totalGroups =
          deltaSeconds / this.currentTimer.totalTime;
      } else {
        Message({
          message: "Please setup Event Information first!",
          type: "error",
          duration: 5 * 1000,
        });
      }
    },

    // ========================================
    onDeleteOneLeaderBoardSetting(row) {
      MessageBox.confirm(
        "Do you confirm delete item: " + row.data.name + "?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        let tempName = row.data.name;
        let uploadSettings = [];

        for (
          let idx = 0;
          idx < this.leaderBoardSettingsTotalList.length;
          idx++
        ) {
          if (this.leaderBoardSettingsTotalList[idx].data.name === tempName) {
            uploadSettings.push(this.leaderBoardSettingsTotalList[idx].data);
          }
        }

        // for (let idx = 0; idx < this.leaderBoardSettingsTotalList.length; idx++) {

        //   if (this.leaderBoardSettingsTotalList[idx].data.name !== tempName) {
        //     uploadSettings.push(this.leaderBoardSettingsTotalList[idx].data);
        //   }
        // }

        HelperSetLeaderBoardRemoveMultiSettings(uploadSettings)
          .then((response) => {
            // get all leader board settings
            this.getAllLeaderBoardSettings();
          })
          .catch((error) => {
            Message({
              message: "Set LeaderBoard All Settings 1: " + error,
              type: "error",
              duration: 5 * 1000,
            });
          });
      });
    },

    // ========================================
    onDeleteOneLDBGroup(row) {
      let rowIdx = -1;
      for (let idx = 0; idx < this.addLDBGroupList.length; idx++) {
        if (this.addLDBGroupList[idx].name === row.name) {
          rowIdx = idx;
          break;
        }
      }

      if (rowIdx >= 0) {
        this.addLDBGroupList.splice(rowIdx, 1);
      }
    },
    // ========================================
    onSelectEventIDFromList(eventID) {
      let isExist = false;
      this.leaderBoardSettingsTotalList.forEach((configLDBSettings) => {
        let eventInfo = configLDBSettings.data.EventInfo;
        if (eventInfo.eventID === eventID) {
          Message({
            message: `LDB of Event ID: ${eventID} is existed, eventName = ${eventInfo.eventName}`,
            type: "error",
            duration: 5 * 1000,
          });
          isExist = true;
          return;
        }
      });

      if (isExist) {
        return false;
      }

      this.eventInformationList.forEach((eventInfo) => {
        if (eventInfo.eventID === eventID) {
          this.currentEventInformation = eventInfo;
          this.onChangeTimer();
          return false;
        }
      });
    },

    // ========================================
    // OTHERS FUNCTIONS
    handleClick(tab, event) {},
  },
};
</script>